<template>
    <content-view>
        <div class="course-finish">
            <div class="course-finish__wrapper">
                <div class="course-finish__icon"></div>
                <h1 class="course-finish__title">
                    Поздравляем, вы успешно прошли тестирование и закончили курс!
                </h1>

                <ValidationObserver
                    tag="form"
                    ref="observer"
                    method="post"
                    class="course-finish__form"
                    @submit.prevent="sendReview"
                >
                    <ValidationProvider
                        vid="review"
                        rules="required"
                        v-slot="provider"
                    >
                        <div
                            class="form-group"
                            v-bind:class="{'is-invalid': provider.errors.length > 0}"
                        >
                            <label class="course-finish__label">
                                Оставьте отзыв об обучении
                            </label>

                            <div class="course-finish__textarea">
                                <v-textArea
                                    :name="`review`"
                                    :max-length="100"
                                    :value="reviewText"
                                    @input="value => reviewText = value"
                                />
                            </div>

                            <div
                                v-if="provider.errors.length > 0"
                                id="id_review-error"
                                class="invalid-feedback animated fadeInDown"
                            >
                                {{provider.errors[0]}}
                            </div>
                        </div>
                    </ValidationProvider>

                    <button
                        type="submit"
                        class="v-btn"
                    >
                        Отправить
                    </button>
                </ValidationObserver>
            </div>
        </div>
    </content-view>
</template>

<script>
    import session from "@/api/session";

    export default {
        name: 'CourseFinish',
        components: {
            ContentView : () => import('@/views/menu/ContentView'),
            vTextArea : () => import('@/components/TextArea')
        },
        data() {
            return {
                reviewText: '',
                courseId: this.$route.params.course_id
            }
        },
        methods: {
            async sendReview() {
                const isValid = await this.$refs.observer.validate();

                if (isValid) {
                    try {
                        const request = session.post(`/api/v1/course/${this.courseId}/review/`, {
                            review: this.reviewText
                        });
                        const response = await request;

                        this.$router.push(`/courses/${this.courseId}`);

                        const ThanksFeedback = () => import("@/components/course/modals/ThanksFeedback");
                        this.$modal.show(
                            ThanksFeedback,
                            {},
                            {
                                name: "thanks-feedback",
                                adaptive: true,
                                maxWidth: 528,
                                width: "100%",
                                height: "auto"
                            }
                        );
                    } catch (err) {
                        console.error(err)
                    }
                }
            },

            onTest(event) {
                console.log(event)
                // reviewText = $event; provider.validate($event)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .course-finish {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 120px 0 80px;

        color: $black;
        background-color: $white;

        @include below($tablet) {
            width: auto;
            padding: 80px 0;
        }

        @include below($mobile) {
            padding: 40px 0;
            margin-bottom: 20px;
        }

        &__wrapper {
            max-width: rem(705);
            text-align: center;
        }

        &__icon {
            margin: 0 auto rem(47);
            width: rem(345);
            height: rem(245);
            background-image: url("~assets/img/course/finish.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @include below($mobile) {
                width: 80%;
                margin-bottom: rem(30);

            }
        }

        &__title {
            margin-bottom: rem(55);

            font-size: rem(40);
            line-height: 1.3;

            @include below($mobile) {
                margin: 0 auto 20px;

                font-size: rem(22);
            }
        }

        &__label {
            font-weight: 600;
            display: block;
            margin-bottom: rem(23);

            @include below($mobile) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 27px;
            }
        }

        &__textarea {
            max-width: rem(355);
            margin: 0 auto 20px;
        }
    }
</style>
